import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'
import validate from 'helpers/validateCpf'
import CustomSelect from "./../../Requests/NewRequestTabs/components/MutiSelect";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  Button,
  ModalHeader,
  ModalBody,
  Label,
  UncontrolledTooltip,
  Table,
  Input,
  Alert
} from 'reactstrap'

import { IMaskInput } from 'react-imask'
import {
  EMPLOYEE,
  EMPLOYEE_PSY,
  ADMIN,
  STUDENT_STAKEHOLDER_TYPE_ID,
  PROFESSOR_STAKEHOLDER_TYPE_ID,
  ADMNISTRATIVE_TECHNICIAN_STAKEHOLDER_TYPE_ID,
  EXTERNAL_COMMUNITY_STAKEHOLDER_TYPE_ID,
  currentRoleConvertion,
  roleNames,
  currentRoleNames
} from 'constants/roles'

import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'


//Import Breadcrumb
import Breadcrumbs from 'components/Common/Breadcrumb'
import DisableModal from 'components/Common/DisableModal'
import EnableModal from 'components/Common/EnableModal'
import EmailModal from 'components/Common/EmailModal'

import {
  getStakeholderTypes
} from 'store/shared/actions'

import {
  getUsers,
  getSectors,
  blockUser,
  addNewUser,
  updateUser,
  disableUser,
  enableUser,
  sendEmail,
  toggleSaveUserModal,
  getRoles,
} from 'store/contacts/actions'

import { map } from 'lodash'



class ContactsList extends Component {
  constructor(props) {
    super(props)
    const blocked = {
      null: true
    }

    this.node = React.createRef()
    this.state = {
      users: [],
      name: '',
      total: 0,
      pages: [],
      sectors: [],
      roles: [],
      currentPage: 1,
      user: '',
      disableModal: false,
      enableModal: false,
      emailModal: false,
      contactListColumns: [
        {
          text: 'id',
          dataField: 'id',
          sort: true,
          hidden: true,
          formatter: (cellContent, user) => <>{user.id}</>,
        },
        {
          dataField: 'img',
          text: '#',
          formatter: (cellContent, user) => (
            <>
              {!user.img ? (
                <div className='avatar-xs'>
                  <span className='avatar-title rounded-circle'>
                    {user.name.charAt(0)}
                  </span>
                </div>
              ) : (
                <div>

                </div>
              )}
            </>
          ),
        },
        {
          text: 'Nome',
          dataField: 'name',
          sort: true,
          formatter: (cellContent, user) => (
            <>
              <h5 className='font-size-14 mb-1'>
                <Link to='#' className={!blocked[user.deletedAt] ? 'text-secondary' : 'text-dark'}>
                  {user.name}
                </Link>
              </h5>
            </>
          ),
        },
        {
          dataField: 'email',
          text: 'Email',
          sort: true,
          formatter: (cellContent, user) => (
            <>
              {map(
                user.email,
                (tag, index) => (
                  <Link
                    to='#'
                    className={!blocked[user.deletedAt] ? 'text-secondary' : 'text-dark'}
                    key={'_skill_' + user.id + index}
                  >
                    {tag}
                  </Link>
                )
              )}

            </>
          )
        },
        {
          text: 'Perfil',
          dataField: 'role',
          sort: true,
          formatter: (cellContent, user) => (
            <>
              {map(
                roleNames[user.role],
                (tag, index) => (
                  <Link
                    to='#'
                    className={!blocked[user.deletedAt] ? 'text-secondary' : 'text-dark'}
                    key={'_skill_' + user.id + index}
                  >
                    {tag}
                  </Link>
                )
              )}

            </>
          ),
        },
        {
          dataField: 'menu',
          isDummyField: true,
          editable: false,
          text: 'Ação',
          formatter: (cellContent, user) => (
            <div className='d-flex gap-3'>
              <Link className='text-success' to='#' id={'messageEdit' + user.id}>
                <i
                  className='mdi mdi-pencil font-size-18'
                  id='edittooltip'
                  aria-label={'Editar usuário' + user.name}
                  onClick={() => this.handleEditUser(user)}
                ></i>
                <UncontrolledTooltip
                  placement='top'
                  target={'messageEdit' + user.id}
                >
                  Editar usuário
                </UncontrolledTooltip>
              </Link>
              <Link className='d-flex gap-3' to='#' id={'messageSend' + user.id}>
                <i
                  className='mdi mdi-email-alert font-size-18'
                  id='sendtooltip'
                  aria-label={'Enviar email de recuperação de senha para o usuário' + user.name}
                  onClick={() => this.onClickSend(user)}
                ></i>
                <UncontrolledTooltip
                  placement='top'
                  target={'messageSend' + user.id}
                >
                  Enviar email de recuperação de senha
                </UncontrolledTooltip>
              </Link>

              <Link className={!blocked[user.deletedAt] ? 'text-success' : 'text-danger'} to='#' id={'messageBlock' + user.id}>
                <i
                  className={!blocked[user.deletedAt] ? 'mdi mdi-check-circle font-size-18' : 'mdi mdi-block-helper font-size-18'}
                  id='deletetooltip'
                  onClick={() => (!blocked[user.deletedAt] ? this.onClickEnable(user) : this.onClickDisable(user))}
                ></i>
                <UncontrolledTooltip
                  placement='top'
                  target={'messageBlock' + user.id}
                >
                  {!blocked[user.deletedAt] ? <div> Habilitar conta </div> : <div> Desabilitar conta </div>}
                </UncontrolledTooltip>
              </Link>
            </div>
          ),
        },
      ],
    }
    this.handleEditUser = this.handleEditUser.bind(this)
    this.handleCreateUser = this.handleCreateUser.bind(this)
    this.onClickDisable = this.onClickDisable.bind(this)
    this.onClickEnable = this.onClickEnable.bind(this)
  }


  componentDidMount() {
    const { users, onGetUsers, onGetRoles, onGetStakeholderTypes, onGetSectors, sectors, roles } = this.props
    if (users && !users.length) {
      onGetUsers(1, '')
    }
    onGetRoles()
    onGetSectors()
    this.setState({ users })
    onGetStakeholderTypes()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { onGetUsers, totalCount, page: currentPage } = this.props
    if (prevProps.totalCount !== this.props.totalCount || prevProps.users !== this.props.users) {
      this.setState({ users: {}, isEdit: this.state.isEdit })
      const totalPages = Math.ceil(totalCount / 10)
      const arrayPages = []
      for (let i = 1; i <= totalPages; i++) {
        arrayPages.push(i)
      }
      this.setState({
        total: totalCount,
        pages: arrayPages,
        currentPage,
      })
    }
    if (prevState.name !== this.state.name) {
      onGetUsers(1, this.state.name)
    }
    if (prevState.currentPage !== this.state.currentPage) {
      onGetUsers(this.state.currentPage, this.state.name)
    }
  }

  handleCreateUser = () => {
    this.setState({ user: undefined, isEdit: false })
    this.props.toggleSaveUserModal()
  }

  toggleDisableModal = () => {
    this.setState(prevState => ({
      disableModal: !prevState.disableModal,
    }))
  }

  toggleEnableModal = () => {
    this.setState(prevState => ({
      enableModal: !prevState.enableModal,
    }))
  }

  toggleEmailModal = () => {
    this.setState(prevState => ({
      emailModal: !prevState.emailModal,
    }))
  }

  onClickDisable = user => {
    this.setState({ user: user })
    this.setState({ disableModal: true })
  }

  onClickEnable = user => {
    this.setState({ user: user })
    this.setState({ enableModal: true })
  }

  onClickSend = users => {
    this.setState({ users: users })
    this.setState({ emailModal: true })
  }

  handleDisableUser = (response) => {
    const { onDisableUser } = this.props
    const { user } = this.state
    if (user.id !== undefined) {
      onDisableUser(user)
      this.setState({ disableModal: false })
    }
  }

  handleEnableUser = (response) => {
    const { onEnableUser } = this.props
    const { user } = this.state
    if (user.id !== undefined) {
      onEnableUser(user)
      this.setState({ enableModal: false })
    }
  }

  handleSendEmail = () => {
    const { onSendEmail } = this.props
    const { users } = this.state
    if (users.id !== undefined) {
      onSendEmail(users)
      this.setState({ emailModal: false })
    }
  }

  handleSectorChange = (value) => {

    if (value != this.sectors) {
      this.sectors = value
    }
  };

  handleEditUser = arg => {
    const user = arg

    this.setState({
      user: {
        id: user.id,
        name: user.name,
        phone: user.phone,
        stakeholderTypeId: user.stakeholderTypeId,
        documentNumber: user.documentNumber,
        role: user.role,
        roles: user.roles ? user.roles : user.userRoles,
        email: user.email,
        deletedAt: user.deletedAt,
        sectors: user.sectors ? user.sectors : user.userSectors
      },
      isEdit: true,
    })

    this.props.toggleSaveUserModal()

  }

  render() {
    document.title = 'Usuários | Atendimento NUPI'
    const { users, totalCount, history, sectors, loading, roles } = this.props
    const { isEdit, disableModal, enableModal, emailModal } = this.state
    const { onUpdateUser, onAddNewUser } = this.props
    const user = this.state.user
    const totalPages = Math.ceil(totalCount / 10)
    const arrayPages = []
    for (let i = 1; i <= totalPages; i++) {
      arrayPages.push(i)
    }
    return (
      <React.Fragment>
        <DisableModal
          show={disableModal}
          onDeleteClick={this.handleDisableUser}
          onCloseClick={() => this.setState({ disableModal: false })}
        />
        <EnableModal
          show={enableModal}
          onDeleteClick={this.handleEnableUser}
          onCloseClick={() => this.setState({ enableModal: false })}
        />
        <EmailModal
          show={emailModal}
          onDeleteClick={this.handleSendEmail}
          onCloseClick={() => this.setState({ emailModal: false })}
        />
        <div className='page-content'>
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title='Configurações' breadcrumbItem='Usuários' />
            <Row>
              <Col lg='12'>
                <Card>
                  <CardBody>
                    <Row className='mb-4'>
                      <Col lg='4 mx-2'>
                        <Row>
                          <Input
                            placeholder='Buscar'
                            onChange={e => {
                              this.setState({ name: e.target.value })
                            }} />
                        </Row>
                      </Col>
                      <Col>
                        <div className='text-sm-end'>
                          <Button color='primary' onClick={this.handleCreateUser}
                            aria-label={'Criar novo usuário'}
                          >
                            <i className='mdi mdi-plus-circle-outline me-1' />
                            Criar usuário
                          </Button>
                        </div>
                      </Col>
                    </Row>
                    <Table responsive>
                      <thead className='text-center border-bottom'>
                        <tr>
                          <th>Nome</th>
                          <th>Email</th>
                          <th>Perfil atual</th>
                          <th>Ação</th>
                        </tr>
                      </thead>
                      <tbody className='text-center'>
                        {users.map(user => (
                          <tr key={user.id} tabIndex="0">
                            <td className='px-0 ' aria-label={'Nome do usuário' + user.name}>
                              {user.name}
                            </td>
                            <td className='px-0' aria-label={'Email do usuário' + user.email}>
                              {user.email}
                            </td>
                            <td className='px-0' aria-label={'Perfil do usuário' + currentRoleNames[user.role]}>
                              {currentRoleNames[user.role]}
                            </td>
                            <td className='px-0' aria-label={'Botões de ações'} >
                              <div className='d-flex gap-3 justify-content-center'>
                                <Link className='text-success' to='#' id={'messageEdit' + user.id} aria-label={'Editar usuário' + user.name} tabIndex='0' onKeyDown={(e) => {
                                  if (e.keyCode === 13) {
                                    e.preventDefault();
                                    this.handleEditUser(user);
                                  }
                                }}>
                                  <i
                                    className='mdi mdi-pencil font-size-18'
                                    id='edittooltip'
                                    onClick={() => this.handleEditUser(user)}
                                  ></i>
                                  <UncontrolledTooltip
                                    placement='top'
                                    target={'messageEdit' + user.id}
                                  >
                                    Editar usuário
                                  </UncontrolledTooltip>
                                </Link>
                                <Link className='d-flex gap-3' to='#' id={'messageSend' + user.id} aria-label={'Enviar email de recuperação de senha para o usuário' + user.name} onKeyDown={(e) => {
                                  if (e.keyCode === 13) {
                                    e.preventDefault();
                                    this.handleEditUser(user);
                                  }
                                }}>
                                  <i
                                    className='mdi mdi-email-alert font-size-18'
                                    id='sendtooltip'
                                    onClick={() => this.onClickSend(user)}
                                  ></i>
                                  <UncontrolledTooltip
                                    placement='top'
                                    target={'messageSend' + user.id}
                                  >
                                    Enviar email de recuperação de senha
                                  </UncontrolledTooltip>
                                </Link>
                                <Link className={!!user.deletedAt ? 'text-success' : 'text-danger'} to='#' id={'messageBlock' + user.id} aria-label={!!user.deletedAt ? 'Habilitar conta do usuário' + user.name : 'Desabilitar contado usuário' + user.name} onKeyDown={(e) => {
                                  if (e.keyCode === 13) {
                                    e.preventDefault();
                                    this.handleEditUser(user);
                                  }
                                }}>
                                  <i
                                    className={!!user.deletedAt ? 'mdi mdi-check-circle font-size-18' : 'mdi mdi-block-helper font-size-18'}
                                    id='deletetooltip'
                                    onClick={() => (!!user.deletedAt ? this.onClickEnable(user) : this.onClickDisable(user))}
                                  ></i>
                                  <UncontrolledTooltip
                                    placement='top'
                                    target={'messageBlock' + user.id}
                                  >
                                    {!!user.deletedAt ? <div> Habilitar conta </div> : <div> Desabilitar conta </div>}
                                  </UncontrolledTooltip>
                                </Link>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    <div className='d-flex flex-row-reverse bd-highlight'>
                      <div className='p-2 bd-highlight'>
                        {this.props.totalCount > 10 ? (
                          <React.Fragment>
                            <button
                              disabled={loading}
                              onClick={() => {
                                this.setState({ currentPage: 1 });
                              }}
                              className='btn btn-primary mx-1'
                              style={{ backgroundColor: 'transparent', border: 'none', color: this.state.currentPage === 1 ? 'lightgray' : '#556ee6' }}
                            >
                              <div style={{ fontSize: '200%' }}> {'<<'} </div>
                            </button>
                            <button
                              disabled={loading || this.state.currentPage === 1}
                              onClick={() => {
                                this.setState({ currentPage: this.state.currentPage - 1 });
                              }}
                              className='btn btn-primary mx-1'
                              style={{ borderRadius: '50%', backgroundColor: 'transparent', border: 'none', color: this.state.currentPage === 1 ? 'lightgray' : '#556ee6' }}
                            >
                              <div style={{ fontSize: '200%' }}> {'<'}</div>
                            </button>
                            {(() => {
                              let filteredPages = [];
                              arrayPages.forEach(page => {
                                if (page - 1 === this.state.currentPage || page + 1 === this.state.currentPage || page === this.state.currentPage) {
                                  filteredPages.push(page);
                                }
                              });
                              return filteredPages.map(page => (
                                <button
                                  disabled={loading || page === this.state.currentPage}
                                  key={page}
                                  onClick={() => {
                                    this.setState({ currentPage: page });
                                  }}
                                  className='btn btn-primary mx-1 rounded-circle'
                                >
                                  {page}
                                </button>
                              ));
                            })()}
                            <button
                              disabled={loading || this.state.currentPage === this.state.pages.length}
                              onClick={() => {
                                this.setState({ currentPage: this.state.currentPage + 1 });
                              }}
                              className='btn btn-primary mx-1'
                              style={{ borderRadius: '50%', backgroundColor: 'transparent', border: 'none', color: this.state.currentPage === this.state.pages.length ? 'lightgray' : '#556ee6' }}
                            >
                              <div style={{ fontSize: '200%' }}> {'>'}</div>
                            </button>
                            <button
                              disabled={loading}
                              onClick={() => {
                                this.setState({ currentPage: this.state.pages.length });
                              }}
                              className='btn btn-primary mx-1'
                              style={{ backgroundColor: 'transparent', border: 'none', color: this.state.currentPage === this.state.pages.length ? 'lightgray' : '#556ee6' }}
                            >
                              <div style={{ fontSize: '200%' }}> {'>>'} </div>
                            </button>
                          </React.Fragment>
                        ) : ''}
                      </div>
                    </div>

                    <Modal
                      isOpen={this.props.saveUserModalActive}
                      className={this.props.className}
                      toggle={this.props.toggleSaveUserModal}
                    >
                      <ModalHeader tag='h4'>
                        {isEdit ? 'Editar usuário' : 'Criar usuário'}
                      </ModalHeader>
                      <ModalBody>
                        <Formik
                          enableReinitialize
                          initialValues={{
                            id: (user && user.id) || undefined,
                            name: (user && user.name) || '',
                            phone: (user && user.phone) || '',
                            stakeholderTypeId: (user && user.stakeholderTypeId) || STUDENT_STAKEHOLDER_TYPE_ID,
                            documentNumber: (user && user.documentNumber) || '',
                            role: (user && user.roles && user.roles.map(role => role.roleId)),
                            email: (user && user.email) || '',
                            password: '',
                            passwordConfirmation: '',
                            sector: (user && user.sectors && user.sectors.map(sector => sector.sectorId)) || []
                          }}
                          validationSchema={Yup.object().shape({
                            name: Yup.string()
                              .required('Informe um nome de usuário válido')
                              .matches(/[A-Z][a-z].* [A-Z][a-z].*/, 'Informe o seu nome completo com iniciais maiúsculas'),
                            email: Yup.string()
                              .required('Informe o seu e-mail')
                              .matches(/\S+@\S+\.\S+/, 'Informe um email válido'),
                            phone: Yup.string()
                              .min(13, 'O número precisa ter 10 ou 11 dígitos')
                              .max(14, 'O número precisa ter 10 ou 11 dígitos')
                              .required('Informe um telefone válido'),
                            stakeholderTypeId: Yup.number()
                              .required('Selecione um vínculo'),
                            documentNumber: Yup.string()
                              .when('stakeholderTypeId', {
                                is: (STUDENT_STAKEHOLDER_TYPE_ID),
                                then: Yup.string().required('Informe o CPF').test('is-calculation-correct', 'CPF inválido', (documentNumber) => validate(documentNumber)),
                              })
                              .when('stakeholderTypeId', {
                                is: (EXTERNAL_COMMUNITY_STAKEHOLDER_TYPE_ID),
                                then: Yup.string().required('Informe o CPF').test('is-calculation-correct', 'CPF inválido', (documentNumber) => validate(documentNumber)),
                              })
                              .when('stakeholderTypeId', {
                                is: (PROFESSOR_STAKEHOLDER_TYPE_ID),
                                then: Yup.string().required('Informe o SIAPE')
                                  .min(7, 'O SIAPE precisa ter 7 dígitos'),
                              })
                              .when('stakeholderTypeId', {
                                is: (ADMNISTRATIVE_TECHNICIAN_STAKEHOLDER_TYPE_ID),
                                then: Yup.string().required('Informe o SIAPE')
                                  .min(7, 'O SIAPE precisa ter 7 dígitos'),
                              }),
                            password: Yup.string()
                              .matches(/^(?=.*[a-zA-Z])(?=.*[0-9.,-]).{8,}$/, 'A senha precisa ter pelo menos 8 caracteres contendo letras e números')
                              .min(8, 'A senha precisa ter pelo menos 8 caracteres')
                              .when('email', {
                                is: (email) => isEdit === false,
                                then: Yup.string()
                                  .required('Informe uma senha válida')
                              }),
                            passwordConfirmation: Yup.string()
                              .oneOf([Yup.ref('password')], 'As senhas não conferem')
                              .when('email', {
                                is: (email) => isEdit === false,
                                then: Yup.string()
                                  .required('Repita a senha informada')
                              })
                          })}
                          onSubmit={(values, user) => {
                            if (isEdit) {
                              const userEdit = {
                                id: values.id,
                                name: values.name,
                                phone: values.phone,
                                stakeholderTypeId: values.stakeholderTypeId,
                                role: this.state.user.role,
                                documentNumber: values.documentNumber,
                                email: values.email,
                              }

                              const userSectors = {
                                sectors: values.sector,
                                userId: values.id
                              }
                              const userRoles = {
                                roles: values.role,
                                userId: values.id
                              }
                              if (Array.isArray(userRoles.roles) && !userRoles.roles.includes(userEdit.role)) {
                                userEdit.role = currentRoleConvertion[userRoles.roles[0]];
                              }
                              const role = userEdit.role
                              onUpdateUser(userEdit, history, userSectors, role, userRoles)
                            } else {
                              onAddNewUser(values, history)
                            }
                          }}
                        >
                          {({ errors, touched, values, setFieldValue }) => (
                            <Form>
                              <Row>
                                <Col className='col-12'>
                                  <div className='mb-3'>
                                    <Label className='form-label'>
                                      Nome
                                    </Label>
                                    <Field
                                      name='name'
                                      type='text'
                                      aria-label={'Campo para preenchimento do nome do usuário'}
                                      className={
                                        'form-control' +
                                        (errors.name &&
                                          touched.name
                                          ? ' is-invalid'
                                          : '')
                                      }
                                    />
                                    <ErrorMessage
                                      name='name'
                                      component='div'
                                      className='invalid-feedback'
                                    />
                                  </div>
                                  <Label for='name' className='form-label'>
                                    Telefone
                                  </Label>
                                  <div className='mb-3'>
                                    <Field
                                      as={IMaskInput}
                                      name='phone'
                                      aria-label={'Campo para preenchimento do telefone do usuário'}
                                      mask='(00)00000-0000'
                                      type='text'
                                      className={
                                        'form-control' +
                                        (errors.phone && touched.phone
                                          ? ' is-invalid'
                                          : '')
                                      }
                                    />
                                    <ErrorMessage
                                      name='phone'
                                      component='div'
                                      className='invalid-feedback'
                                    />
                                  </div>
                                  <div className='mb-3'>
                                    <Label for='stakeholderTypeId' className='form-label'>
                                      Vínculo
                                    </Label>
                                    <Field
                                      className='form-control'
                                      as='select'
                                      aria-label={'Caixa de seleção do tipo de vínculo do usuário'}
                                      name='stakeholderTypeId'
                                      onChange={event => {
                                        setFieldValue('stakeholderTypeId', Number(event.currentTarget.value))
                                      }}
                                    >
                                      {this.props.stakeholderTypes.map((stakeholderType, index) => (
                                        <option key={index} value={stakeholderType.id}>
                                          {stakeholderType.name}
                                        </option>
                                      ))}
                                    </Field>
                                    <ErrorMessage
                                      name='stakeholderTypeId'
                                      component='div'
                                      className='invalid-feedback'
                                    />
                                  </div>
                                  {
                                    (values.stakeholderTypeId === ADMNISTRATIVE_TECHNICIAN_STAKEHOLDER_TYPE_ID || values.stakeholderTypeId === PROFESSOR_STAKEHOLDER_TYPE_ID)
                                    && (
                                      <div className='mb-3'>
                                        <Label for='documentNumber' className='form-label'>
                                          <div>SIAPE</div>
                                        </Label>
                                        <Field
                                          as={IMaskInput}
                                          mask={'0000000'}
                                          aria-label={'Campo para preenchimento do numero do siape do servidor'}
                                          name={'documentNumber'}
                                          type='text'
                                          className={
                                            'form-control' +
                                            (errors.documentNumber && touched.documentNumber
                                              ? ' is-invalid'
                                              : '')
                                          }
                                        />
                                        <ErrorMessage
                                          name={'documentNumber'}
                                          component='div'
                                          className='invalid-feedback'
                                        />
                                      </div>
                                    )
                                  }

                                  {isEdit && (
                                    <div>
                                      <div className='mb-3'>
                                        <Label className='form-label'>
                                          Perfil
                                        </Label>
                                        <div>
                                          <Field
                                            name='role'
                                            component={CustomSelect}
                                            options={this.props.roles.map(role => ({ value: role.id, label: roleNames[role.id] }))}
                                            placeholder='Selecione um ou mais perfis'
                                            isMulti
                                            className="mb-2"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  )}

                                  {(values.stakeholderTypeId === STUDENT_STAKEHOLDER_TYPE_ID || values.stakeholderTypeId === EXTERNAL_COMMUNITY_STAKEHOLDER_TYPE_ID)
                                    && (
                                      <div className='mb-3'>
                                        <Label for='documentNumber' className='form-label'>
                                          <div>CPF</div>
                                        </Label>
                                        <Field
                                          as={IMaskInput}
                                          mask={'000.000.000-00'}
                                          aria-label={'Campo para preenchimento do numero do cpf do usuário'}
                                          name={'documentNumber'}
                                          type='text'
                                          className={
                                            'form-control' +
                                            (errors.documentNumber && touched.documentNumber
                                              ? ' is-invalid'
                                              : '')
                                          }
                                        />
                                        <ErrorMessage
                                          name={'documentNumber'}
                                          component='div'
                                          className='invalid-feedback'
                                        />
                                      </div>
                                    )
                                  }
                                  <div className='mb-3'>
                                    <Label className='form-label'>
                                      E-mail
                                    </Label>
                                    <Field
                                      name='email'
                                      type='text'
                                      aria-label={'Campo para preenchimento do numero do email do usuário'}
                                      className={
                                        'form-control' +
                                        (errors.email &&
                                          touched.email
                                          ? ' is-invalid'
                                          : '')
                                      }
                                    />
                                    <ErrorMessage
                                      name='email'
                                      component='div'
                                      className='invalid-feedback'
                                    />
                                  </div>

                                  {isEdit && Array.isArray(values.role) && values.role.some(role => role === EMPLOYEE || role === ADMIN || role === EMPLOYEE_PSY) && (
                                    <div className="my-3">
                                      <Label htmlFor="sector" className="form-label">
                                        Setor
                                      </Label>
                                      <div>
                                        {sectors.map((sector, index) => {
                                          const sectorId = sector.id;
                                          const isChecked = Array.isArray(values.sector) && values.sector.includes(sectorId);
                                          return (
                                            <div className="form-check mb-2" key={index}>
                                              <Field
                                                type="checkbox"
                                                className="form-check-input input-mini"
                                                id={`sector-${sectorId}`}
                                                name="sector"
                                                value={sectorId}
                                                checked={isChecked}
                                                onChange={(event) => {
                                                  const sectorId = parseInt(event.target.value);
                                                  const updatedSectors = values.sector?.includes(sectorId)
                                                    ? values.sector.filter((id) => id !== sectorId)
                                                    : [...(values.sector || []), sectorId];
                                                  setFieldValue('sector', updatedSectors);
                                                }}
                                                aria-label={`Selecionar o setor ${sector.sector}`}
                                              />
                                              <Label
                                                className="form-check-label"
                                                htmlFor={`sector-${sectorId}`}
                                              >
                                                {sector.sector}
                                              </Label>
                                            </div>
                                          );
                                        })}
                                      </div>
                                    </div>
                                  )}

                                  {!isEdit && (
                                    <div>
                                      <div className='mb-3'>
                                        <Label className='form-label'>
                                          Senha
                                        </Label>
                                        <Field
                                          name='password'
                                          type='password'
                                          aria-label={'Campo para o preenchomento da senha do usuário'}
                                          className={
                                            'form-control' +
                                            (errors.password &&
                                              touched.password
                                              ? ' is-invalid'
                                              : '')
                                          }
                                        />
                                        <ErrorMessage
                                          name='password'
                                          component='div'
                                          className='invalid-feedback'
                                        />

                                      </div>
                                      <div className='mb-3'>
                                        <Label className='form-label'>
                                          Confirmação de senha
                                        </Label>
                                        <Field
                                          name='passwordConfirmation'
                                          aria-label={'Campo para a confirmação da senha '}
                                          type='password'
                                          className={
                                            'form-control' +
                                            (errors.passwordConfirmation &&
                                              touched.passwordConfirmation
                                              ? ' is-invalid'
                                              : '')
                                          }
                                        />
                                        <ErrorMessage
                                          name='passwordConfirmation'
                                          component='div'
                                          className='invalid-feedback'
                                        />
                                      </div>
                                    </div>
                                  )}
                                </Col>
                              </Row>
                              {
                                this.props.errorMessage && (
                                  <Alert color='danger'>{this.props.errorMessage}</Alert>
                                )
                              }
                              <Row>
                                <Col>
                                  <div className='text-end'>
                                    <button
                                      type='submit'
                                      className='btn btn-success save-user'
                                      disabled={this.props.loading}
                                      aria-label={isEdit ? 'Confirmar e salvar alterações no usuário' : 'Confirmar e cadastrar novo usuário'}
                                    >
                                      {this.props.loading ? 'Aguarde...' : 'Salvar'}
                                    </button>
                                  </div>
                                </Col>
                              </Row>
                            </Form>
                          )}
                        </Formik>
                      </ModalBody>
                    </Modal>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div >
      </React.Fragment >
    )
  }
}

ContactsList.propTypes = {
  errorMessage: PropTypes.any,
  users: PropTypes.array,
  className: PropTypes.any,
  onGetUsers: PropTypes.func,
  onGetSectors: PropTypes.func,
  onGetRoles: PropTypes.func,
  onAddNewUser: PropTypes.func,
  onDisableUser: PropTypes.func,
  onEnableUser: PropTypes.func,
  onUpdateUser: PropTypes.func,
  onBlockUser: PropTypes.func,
  onSendEmail: PropTypes.func,
  ongetStakeholderTypes: PropTypes.func,
  stakeholderTypes: PropTypes.array,
  loading: PropTypes.bool,
}

const mapStateToProps = ({ shared, contacts }) => ({
  errorMessage: contacts.error || shared.error,
  users: contacts.users,
  sectors: contacts.sectors,
  roles: contacts.roles,
  stakeholderTypes: shared.stakeholderTypes,
  totalCount: contacts.totalCount,
  pageCount: contacts.pageCount,
  page: contacts.page,
  saveUserModalActive: contacts.saveUserModalActive,
  loading: contacts.loading
})

const mapDispatchToProps = dispatch => ({
  onGetStakeholderTypes: () => dispatch(getStakeholderTypes()),
  toggleSaveUserModal: () => dispatch(toggleSaveUserModal()),
  onGetUsers: (page = 1, name = '') => dispatch(getUsers(page, name)),
  onGetSectors: () => dispatch(getSectors()),
  onGetRoles: () => dispatch(getRoles()),
  onAddNewUser: (values, history) => dispatch(addNewUser(values, history)),
  onUpdateUser: (userEdit, history, userSectors, role, userRoles) => dispatch(updateUser(userEdit, history, userSectors, role, userRoles)),
  onDisableUser: (user) => dispatch(disableUser(user)),
  onEnableUser: (user) => dispatch(enableUser(user)),
  onBlockUser: user => dispatch(blockUser(user)),
  onSendEmail: user => dispatch(sendEmail(user))
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ContactsList)
)