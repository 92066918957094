import { api } from "../../services/api";
import { processErrorMessage } from "../../helpers/errors";
import {
  GET_USERS_AVALIABILITY_SUCCESS,
  GET_USERS_AVALIABILITY_FAIL,
  API_ERROR,
  GET_USERS_AVALIABILITY_LOADING,
} from "./actionTypes";

export const getUsersAv = (page = 1, name = '') => dispatch => {
  dispatch({ type: GET_USERS_AVALIABILITY_LOADING })
  api.get(`account/users?page=${page}&name=${name}`, { withCredentials: true })
    .then(response => {
      dispatch({
        type: GET_USERS_AVALIABILITY_SUCCESS,
        payload: response.data,
      })
    })
    .catch(error => {
      processErrorMessage(dispatch, error, API_ERROR)
    })
}


export const getTeamAvailabilitySuccess = users => ({
  type: GET_USERS_AVALIABILITY_SUCCESS,
  payload: users,
});

export const getTeamAvailabilityFail = error => ({
  type: GET_USERS_AVALIABILITY_FAIL,
  payload: error,
});

export const apiReqCanceledError = error => ({
  type: API_ERROR,
  payload: error,
});
