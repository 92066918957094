import React from 'react';
import { Redirect } from 'react-router-dom';

// User profile
import UserProfile from '../pages/Authentication/UserProfile';

// Authentication related pages
import Login from '../pages/Authentication/Login';
import Logout from '../pages/Authentication/Logout';
import Register from '../pages/Authentication/Register';
import ForgetPwd from '../pages/Authentication/ForgetPassword';
import ChangePassword from 'pages/Authentication/ChangePassword';

// Requests related pages
import MyRequests from 'pages/Requests/MyRequests';
import NewRequest from 'pages/Requests/NewRequest';
import ViewRequest from 'pages/Requests/ViewRequest';
import RequestAnalysis from 'pages/Requests/RequestAnalysis';

// Dashboard
import Dashboard from '../pages/Dashboard/index';
import Schedules from '../pages/Calendar/index';
import Appointments from '../pages/Appointments/index';


// User management
import ContactsList from '../pages/Contacts/ContactList/contacts-list';
import CanceledRequests from '../pages/Reporting/CanceledRequests/canceled-requests-report';
import AppointmentsList from 'pages/AppointmentsList/appointments-list';
import { ROLE_ADMIN, ROLE_EMPLOYEE, ROLE_EMPLOYEE_PSY } from 'constants/roles';
import RequestConfirm from 'pages/Requests/RequestConfirm';
import RejectRequests from 'pages/Requests/RejectRequests';
import { getLoggedUser } from 'helpers/getLoggedUser';
import TeamAvalibility from 'pages/Settings/availability';


const user = getLoggedUser();

const setDashboardComponent = () => {
  if (user && user.role === ROLE_ADMIN || !user) {
    return <Dashboard />;
  }else if(user.role === ROLE_EMPLOYEE || user.role === ROLE_EMPLOYEE_PSY) {
    return <Redirect to="/schedules" />;
  }

  return <Redirect to="/my-requests" />;
}

const authProtectedRoutes = [
  { path: '/dashboard', component: () => setDashboardComponent() },
  { path: '/profile', component: UserProfile },
  { path: '/contacts-list', component: ContactsList, allowedToRoles: [ROLE_ADMIN] },
  { path: '/canceled-requests-report', component: CanceledRequests, allowedToRoles: [ROLE_ADMIN] },
  { path: '/team-availability', component: TeamAvalibility, allowedToRoles: [ROLE_ADMIN] },
  { path: '/appointments-list', component: AppointmentsList, allowedToRoles: [ROLE_ADMIN] },
  { path: '/my-requests', component: MyRequests },
  { path: '/new-request', component: NewRequest },
  { path: '/view-request/:id', component: ViewRequest },
  { path: '/request-analysis/:id', component: RequestAnalysis, allowedToRoles: [ROLE_ADMIN] },
  { path: '/confirm-request/:id', component: RequestConfirm, allowedToRoles: [ROLE_ADMIN]},
  { path: '/schedules', component: Schedules },
  { path: '/appointments', component: Appointments, allowedToRoles: [ROLE_ADMIN]},
  { path: '/reject-request/:id', component: RejectRequests, allowedToRoles: [ROLE_ADMIN]},

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: '/', exact: true, component: () => <Redirect to='/dashboard' /> },
  
]

const publicRoutes = [
  { path: '/logout', component: Logout },
  { path: '/login', component: Login },
  { path: '/forgot-password', component: ForgetPwd },
  { path: '/change-password/:token', component: ChangePassword },
  { path: '/register', component: Register }
]

export { publicRoutes, authProtectedRoutes }