/* USERS */
export const GET_USERS_AVALIABILITY_SUCCESS = "GET_USERS_AVALIABILITY_SUCCESS"
export const GET_USERS_AVALIABILITY_FAIL = "GET_USERS_AVALIABILITY_FAIL"
export const API_ERROR = "API_ERROR"
export const GET_USERS_AVALIABILITY_LOADING = "GET_USERS_AVALIABILITY_LOADING"





