import {
  GET_USERS_AVALIABILITY_SUCCESS,
  GET_USERS_AVALIABILITY_FAIL,
  API_ERROR,
  GET_USERS_AVALIABILITY_LOADING
} from "./actionTypes"

const INIT_STATE = {
  requests: [],
  pageCount: 0,
  totalCount: 0,
  page: 1,
  error: "",
  loading: false,
}

const settings = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_USERS_AVALIABILITY_LOADING:
      return {
        ...state,
        error: "",
        loading: true
      }
    case GET_USERS_AVALIABILITY_SUCCESS:
      return {
        ...state,
        error: "",
        requests: action.payload.attendance,
        pageCount: action.payload.pageCount,
        totalCount: action.payload.totalCount,
        page: action.payload.page,
        loading: false
      }
    case GET_USERS_AVALIABILITY_FAIL: 
      return {
        ...state,
        error: action.payload,
        loading: false
      }
    case API_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false
      }
    default:
      return state
  }
}

export default settings
