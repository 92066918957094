import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Button,
  Label,
  UncontrolledTooltip,
  Table,
  Input,

} from 'reactstrap'
//Import Breadcrumb
import Breadcrumbs from 'components/Common/Breadcrumb'
import moment from 'moment'
import {
  getUsersAv,
} from 'store/settings/actions'

import { map } from 'lodash'

class  TeamAvailability extends Component {
  constructor(props) {
    super(props)
    this.node = React.createRef()
    this.state = {
      users: [],
      name: '',
      total: 0,
      pages: [],
      sectors: [],
      currentPage: 1,
      user: '',
      startDate: "",
      endDate: "",
    }

  }

  componentDidMount() {
    const { users, onGetUsersAv } = this.props
    if (users && !users.length) {
      onGetUsersAv(1, '')
      this.setState({
        users
      })
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { users, onGetUsersAv, totalCount, page: currentPage } = this.props
    if (prevProps.page !== this.props.page || prevState.currentPage !== this.state.currentPage || prevState.startDate !== this.state.startDate || prevState.endDate !== this.state.endDate) {
      onGetUsersAv(this.state.currentPage, '')
    }
  
    if (prevProps.totalCount !== this.props.totalCount) {
      const totalPages = Math.ceil(totalCount / 10)
      const arrayPages = []
      for (let i = 1; i <= totalPages; i++) {
        arrayPages.push(i)
      }
      this.setState({
        total: totalCount,
        pages: arrayPages,
        currentPage,
      })
    }
  }

  render() {
    document.title = 'Solicitações Rejeitadas | Atendimento NUPI'
    const { users, totalCount, history, sectors } = this.props
    const totalPages = Math.ceil(totalCount / 10)
    const arrayPages = []
    for (let i = 1; i <= totalPages; i++) {
      arrayPages.push(i)
    }
    return (
      <React.Fragment>
        <div className='page-content'>
          <Container fluid>
            <Breadcrumbs title='Relatórios' breadcrumbItem='Solicitações rejeitadas' />
            <Row>
              <Col lg='12'>
                <Card>
                  <CardBody>
                    <Row>
                      <Col lg="12">
                        <Row>
                          <Col md="5" className="align-items-baseline">
                            <Label for="startDate" className="form-label">Data inicial</Label>
                            <div>
                              <Input type="date" name="startDate" id="startDate" style={{
                                width: '100%',
                                padding: '8px',
                                boxSizing: 'border-box',
                                border: '1px solid #ced4da',
                                borderRadius: '5px',
                                fontSize: '14px'
                              }} onChange={e => this.setState({ startDate: e.target.value })} />
                            </div>
                          </Col>
                          <Col md="5" className="align-items-baseline">
                            <Label for="endDate" className="form-label">Data final</Label>
                            <div>
                              <Input type="date" name="endDate" id="endDate" style={{
                                width: '100%',
                                padding: '8px',
                                boxSizing: 'border-box',
                                border: '1px solid #ced4da',
                                borderRadius: '5px',
                                fontSize: '14px'
                              }} onChange={e => this.setState({ endDate: e.target.value })} />
                            </div>
                          </Col>
                         
                        </Row>
                        <Table responsive>
                        </Table>
                      </Col>
                    </Row>

                    <Table responsive>
                      <thead className='text-center border-bottom'>
                        <tr>
                          <th>Nome</th>
                          <th>Setor</th>
                          <th>Solicitação</th>
                          <th>Data</th>
                          <th>Rejeitada em</th>
                          <th>Motivo</th>
                        </tr>
                      </thead>
                      <tbody className='text-center'>
                        {users.map(user => (
                          <tr key={user.id} tabIndex="0">
                            <td className='px-0 ' aria-label={'Nome do solicitante' + user.creator.name}>
                              {user.creator.name}
                            </td>
                            <td className='px-0' aria-label={'Setor da solicitação' + user.sector.sector}>
                              {user.sector.sector}
                            </td>
                            <td className='px-0' aria-label={'Nome do evento' + user.eventName}>
                              {user.eventName}
                            </td>
                            <td className='px-0' aria-label={'Data de criação' + moment(user.createdAt).format('DD/MM/YYYY HH:mm')}>
                              {moment(user.createdAt).format('DD/MM/YYYY HH:mm')}
                            </td>
                            <td className='px-0' aria-label={'Data de rejeição' + moment(user.updatedAt).format('DD/MM/YYYY HH:mm')}>
                              {moment(user.updatedAt).format('DD/MM/YYYY HH:mm')}
                            </td>
                            <td className='px-0' aria-label={'Perfil do usuário' + user.rejectedReason}>
                              {user.rejectedReason}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    <div className='d-flex flex-row-reverse bd-highlight'>
                      <div className='p-2 bd-highlight'>
                        {this.props.totalCount > 10 ? (
                          <React.Fragment>
                            <button
                              onClick={() => {
                                this.setState({ currentPage: 1 });
                              }}
                              className='btn btn-primary mx-1 '
                              style={{ backgroundColor: 'transparent', border: 'none', color: this.state.currentPage === 1 ? 'lightgray' : '#556ee6' }}
                            >
                              <div style={{ fontSize: '200%' }}> {'<<'} </div>
                            </button>
                            <button
                              disabled={this.state.currentPage === 1}
                              onClick={() => {
                                this.setState({ currentPage: this.state.currentPage - 1 });
                              }}
                              className='btn btn-primary mx-1'
                              style={{ borderRadius: '50%', backgroundColor: 'transparent', border: 'none', color: this.state.currentPage === 1 ? 'lightgray' : '#556ee6' }}
                            >
                              <div style={{ fontSize: '200%' }}> {'<'}</div>
                            </button>
                            {(() => {
                              let filteredPages = [];
                              arrayPages.forEach(page => {
                                if (page - 1 === this.state.currentPage || page + 1 === this.state.currentPage || page === this.state.currentPage) {
                                  filteredPages.push(page);
                                }
                              });
                              return filteredPages.map(page => (
                                <button
                                  disabled={page === this.state.currentPage}
                                  key={page}
                                  onClick={() => {
                                    this.setState({ currentPage: page });
                                  }}
                                  className='btn btn-primary mx-1 rounded-circle'
                                >
                                  {page}
                                </button>
                              ));
                            })()}
                            <button
                              disabled={this.state.currentPage === this.state.pages.length}
                              onClick={() => {
                                this.setState({ currentPage: this.state.currentPage + 1 });
                              }}
                              className='btn btn-primary mx-1'
                              style={{ borderRadius: '50%', backgroundColor: 'transparent', border: 'none', color: this.state.currentPage === this.state.pages.length ? 'lightgray' : '#556ee6' }}
                            >
                              <div style={{ fontSize: '200%' }}> {'>'}</div>
                            </button>
                            <button
                              onClick={() => {
                                this.setState({ currentPage: this.state.pages.length });
                              }}
                              className='btn btn-primary mx-1 '
                              style={{ backgroundColor: 'transparent', border: 'none', border: 'none', color: this.state.currentPage === this.state.pages.length ? 'lightgray' : '#556ee6' }}
                            >
                              <div style={{ fontSize: '200%' }}> {'>>'} </div>
                            </button>
                          </React.Fragment>
                        ) : ''}
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div >
      </React.Fragment >
    )
  }
}

TeamAvailability.propTypes = {
  errorMessage: PropTypes.any,
  users: PropTypes.array,
  onGetUsersAv: PropTypes.func,
}

const mapStateToProps = (state) => ({
  errorMessage: state.settings.error,
  users: state.settings.requests,
  currentPage: state.settings.page,
  totalCount: state.settings.totalCount,
  pageCount: state.settings.pageCount,
  page: state.settings.page,
  loading: state.settings.loading
})


const mapDispatchToProps = dispatch => ({
    onGetUsersAv: (page = 1, name = '') => dispatch(getUsersAv(page, name)),
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TeamAvailability)
)
